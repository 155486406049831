import { Box, BoxProps } from "@biblioteksentralen/react";
import { DecorativeImage, ImageWithMetadata } from "@libry-content/types";
import NextImage from "next/legacy/image";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { SanityImageCore } from "./SanityImageCore";

type Props = {
  image?: DecorativeImage | ImageWithMetadata;
  resolution: number;
  alt?: string;
  customNextImageProps?: Partial<React.ComponentProps<typeof NextImage>>;
} & BoxProps;

export function SanityImagePreservingAspectRatio({
  image,
  resolution,
  boxShadow = "md",
  alt,
  customNextImageProps,
  ...chakraProps
}: Props) {
  const { t } = useTranslation();
  if (!image?.asset) return null;

  return (
    <>
      <Box
        lineHeight={0}
        borderRadius="0.5em"
        boxShadow={boxShadow}
        position="relative"
        overflow="hidden"
        {...chakraProps}
      >
        <SanityImageCore
          image={image}
          resolution={resolution}
          aspectRatio="original"
          customNextImageProps={{ alt, ...customNextImageProps }}
        />
      </Box>
      {image.creator && (
        // Use absolute positioned box; a wrapper around component breaks book list preview layout
        <Box
          fontStyle="italic"
          marginTop="0.1rem"
          fontSize="sm"
          opacity={0.8}
          position="absolute"
          top="100%"
          left="50%;"
          transform="translateX(-50%)"
          whiteSpace="nowrap"
        >
          {t("Foto: {opphavsPerson}", { opphavsPerson: image.creator })}
        </Box>
      )}
    </>
  );
}
